import styled, { css } from 'styled-components';
import { Container, InnerGrid } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing } from '../../../styles/settings/spacing';
import { weight, size, lineHeight } from '../../../styles/settings/typography';
import { above, below } from '../../../styles/tools/media';
import aspectRatio from '../../../styles/tools/aspectRatio';
import { bigTitleRegular, mdTitleRegular , smBodyLight } from '../../atoms/Typography/index.styled';



type HorizontalCardsType = {
	hasThemeInvert?: boolean
};


const HorizontalCards = styled(Container)<HorizontalCardsType>`
	padding-top: ${spacing[48]};
	padding-bottom: ${spacing[48]};

	${(props: HorizontalCardsType) => props.hasThemeInvert &&
		css`
		padding-top: 48px;
		padding-bottom: 48px;
	`}

	background-color: ${(props: HorizontalCardsType) => props.hasThemeInvert ? 'var(--secondaryBackground)' : 'transparent'};

	${above(
			breakpoints[960],
			() => css`
			padding-top: ${spacing[64]};
			padding-bottom: ${spacing[64]};
		`
		)}

	${above(
			breakpoints[1440],
			() => css`
			padding-top: ${spacing[80]};
			padding-bottom: ${spacing[80]};
		`
		)}
`;

const HorizontalCards__Title = styled(bigTitleRegular)`
	margin-bottom: ${gap[24]};
	color: var(--primaryForeground);

	${above(
	breakpoints[960],
	() => css`
			text-align: center;
		`
)}
`;

const HorizontalCards__CardsList = styled(InnerGrid)`
	grid-row-gap: ${gap[16]};
	margin-top: ${gap[32]};
	padding-left: 0;
	list-style-type: none;

	${above(
	breakpoints[960],
	() => css`
			display: grid;
			grid-template-columns: repeat(12, 1fr);
			grid-row-gap: ${gap[24]};
			margin-top: ${gap[48]};
		`
)}

	${above(
	breakpoints[1920],
	() => css`
			grid-row-gap: ${gap[32]};
		`
)}
`;

const HorizontalCards__CardsItem = styled.li<HorizontalCardsType>`
	grid-column: 1 / -1;
	background-color: ${(props: HorizontalCardsType) => props.hasThemeInvert ? 'var(--white)' : 'var(--secondaryBackground)'};

	${above(
	breakpoints[960],
	() => css`
			display: flex;
			grid-column: auto / span 6;

			&:only-child {
				grid-column: 4 / span 6;
			}
		`
	)}

	${above(
	breakpoints[1440],
	() => css`
			grid-column: auto / span 5;

			&:nth-of-type(odd) {
				grid-column-start: 2;
			}

			&:only-child {
				grid-column: 4 / span 6;
			}
		`
)}
`;

const HorizontalCards__Card = styled.article`
	${above(
	breakpoints[960],
	() => css`
			display: grid;
			grid-template-columns: repeat(5, 1fr);
			grid-column-gap: ${gap[24]};
			width: 100%;
		`
)}

	${above(
	breakpoints[1920],
	() => css`
			grid-column-gap: ${gap[32]};
		`
)}
`;

const HorizontalCards__CardImage = styled.div`
	${below(
	breakpoints[960],
	() => css`
			picture {
				display: none;
			}
		`
)}

	${above(
	breakpoints[960],
	() => css`
			grid-column: 1 / span 2;

			picture {
				height: 100%;
				padding-top: ${aspectRatio('1:1')};
			}
		`
)}
`;

const HorizontalCards__CardContent = styled.div`
	padding: ${gap[24]} ${gap[16]};

	${above(
	breakpoints[960],
	() => css`
			display: flex;
			flex-direction: column;
			justify-content: center;
			grid-column: 3 / -1;
			padding-right: ${gap[24]};
			padding-left: ${gap[0]};
		`
)}
`;

const HorizontalCards__CardBody = styled.p`
	margin-top: ${gap[16]};
	font-family: synthese, sans-serif;
	font-size: ${size[16]};
	font-weight: ${weight[200]};
	line-height: ${lineHeight[24]};

	${above(
	breakpoints[960],
	() => css`
			margin-top: ${gap[24]};
		`
)}
`;

const HorizontalCards__Body = styled(smBodyLight)`
	${above(
	breakpoints[960],
	() => css`
			text-align: center;
			max-width: 628px;
			margin: auto;
			margin-top: 8px;
		`
)}
`;

const HorizontalCards__CardHeading = styled(mdTitleRegular)`
	color: var(--primaryForeground);
	margin-bottom: -10px;

	${above(
	breakpoints[960],
	() => css`
			margin-bottom: -20px;
		`
)}
`;

export default {
	HorizontalCards,
	HorizontalCards__Title,
	HorizontalCards__CardsList,
	HorizontalCards__CardsItem,
	HorizontalCards__Card,
	HorizontalCards__CardImage,
	HorizontalCards__CardContent,
	HorizontalCards__CardBody,
	HorizontalCards__Body,
	HorizontalCards__CardHeading
};
