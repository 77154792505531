import React from 'react';
import LinkStandard from '../../atoms/LinkStandard';
import CustomImage from '../../atoms/CustomImage';
import Styled from './index.styled';

type ImageTypes = {
	src: string
	alt: string
	imageType?: string
};

type LinkTypes = {
	url: string
	title: string
	dataGAAction: string
	dataGALabel: string
	dataGACategory: string
};

type CardTypes = {
	id: string
	image: ImageTypes
	link: LinkTypes
	body: string
	heading?: string
};

type HorizontalCardsTypes = {
	title: string
	body?: string
	cards: Array<CardTypes>
	hasThemeInvert?: boolean
	anchorId?: string
};

const renderCard = (card: CardTypes, hasThemeInvert) => (
		<Styled.HorizontalCards__CardsItem
			key={card.id}
			hasThemeInvert={hasThemeInvert}
		>
			<Styled.HorizontalCards__Card>
				<Styled.HorizontalCards__CardImage>
					<CustomImage {...card.image} width="100" height="100"/>
				</Styled.HorizontalCards__CardImage>
				<Styled.HorizontalCards__CardContent>
					{card.heading ? (
						<>
							<Styled.HorizontalCards__CardHeading as="h2">
								{card.heading}
							</Styled.HorizontalCards__CardHeading>
							<Styled.HorizontalCards__CardBody>
								{card.body}
							</Styled.HorizontalCards__CardBody>
							<Styled.HorizontalCards__CardBody>
								<LinkStandard {...card.link} />
							</Styled.HorizontalCards__CardBody>
						</>
					) : (
						<>
							<LinkStandard {...card.link} />
							<Styled.HorizontalCards__CardBody>
								{card.body}
							</Styled.HorizontalCards__CardBody>
						</>
					)}
				</Styled.HorizontalCards__CardContent>
			</Styled.HorizontalCards__Card>
		</Styled.HorizontalCards__CardsItem>
	);

const HorizontalCards = ({
	title,
	body,
	cards,
	hasThemeInvert,
	anchorId,
}: HorizontalCardsTypes) => (
	<Styled.HorizontalCards
		as="section"
		hasThemeInvert={hasThemeInvert}
		id={anchorId || ''}
	>
		<Styled.HorizontalCards__Title as="h2">
			{title}
		</Styled.HorizontalCards__Title>
		{body && (
			<Styled.HorizontalCards__Body>{body}</Styled.HorizontalCards__Body>
		)}
		<Styled.HorizontalCards__CardsList as="ul">
			{cards.map(card => renderCard(card, hasThemeInvert))}
		</Styled.HorizontalCards__CardsList>
	</Styled.HorizontalCards>
);

export default HorizontalCards;
